<template>
  <section>
    <template v-if="value && !loading">
      <template v-if="value.repertoire.length">
        <UiMasonry
          v-if="isMdAndUp"
          :items="value.repertoire"
          :columns="isLgAndDown ? 2 : 3"
          gap="24px"
        >
          <template #default="{ item }">
            <RouterLink
              :to="{ name: 'EventView', params: { id: item.id } }"
              custom
              v-slot="{ href, navigate }"
            >
              <div>
                <a :href="href" @click.prevent="navigate()">
                  <EventCard :value="item" size="big" bordered />
                </a>
              </div>
            </RouterLink>
          </template>
        </UiMasonry>
        <ul v-else class="grid grid-cols-2 gap-3 lg:grid-cols-3">
          <RouterLink
            v-for="(item, index) in value.repertoire"
            :key="item.id"
            :to="{ name: 'EventView', params: { id: item.id } }"
            custom
            v-slot="{ href, navigate }"
          >
            <li
              :class="index > 0 && !((index + 1) % 7) ? 'lg:col-span-1 col-span-2' : 'col-span-1'"
            >
              <a :href="href" @click.prevent="navigate()">
                <EventCard :value="item" size="big" />
              </a>
            </li>
          </RouterLink>
        </ul>
      </template>
      <p v-else class="text-sm text-text-primary">
        Подходящих событий не найдено. Попробуйте сбросить фильтр<br />или выбрать другие параметры
        поиска
      </p>
    </template>
    <template v-else>
      <CategoryResultsMasonrySkeleton />
    </template>
  </section>
</template>

<script setup lang="ts">
import type { Category } from '@/5_entities/Category'
import { EventCard } from '@/5_entities/Event'
import { useBreakpoint } from '@/6_shared/lib'
import { UiMasonry } from '@/6_shared/ui'
import CategoryResultsMasonrySkeleton from './CategoryResultsMasonrySkeleton.vue'

type PropType = {
  value?: Category
  loading?: boolean
}

withDefaults(defineProps<PropType>(), {
  value: undefined,
  loading: false
})
const {
  lg: [_, isLgAndDown],
  md: [isMdAndUp]
} = useBreakpoint()
</script>
