<template>
  <main class="pb-10 pt-5 lg:pt-10">
    <UiContainer class="mb-3 lg:mb-4">
      <header class="mb-3 flex items-center">
        <GoBack class="mr-2 !size-10 bg-transparent md:hidden" />
        <UiTitle severity="h1" class="!text-base md:!text-3xl">{{ category?.name }}</UiTitle>
      </header>
      <div v-if="isMdAndDown">
        <UiButton
          severity="tertiary"
          size="small"
          class="flex items-center gap-2.5 rounded-xl"
          @click="mobileCalendarIsOpen = true"
        >
          <UiIcon name="calendar" class="size-5" />
          Календарь
          <div
            v-if="!!datepickerValue"
            class="absolute right-1 top-1 size-2 rounded-full bg-main"
          />
        </UiButton>

        <UiDialog v-model="mobileCalendarIsOpen" swipe-close position="bottom">
          <header>
            <UiButton
              icon
              class="!absolute right-4 top-7 z-20 size-7 bg-primary"
              @click="mobileCalendarIsOpen = false"
            >
              <UiIcon name="x-mark" class="size-5 text-text-main lg:text-light" />
            </UiButton>
          </header>
          <UiDatePicker
            :model-value="datepickerValue"
            range
            need-confirm
            show-reset
            header-classes="mb-3 flex px-0 pt-7 "
            class="scrollbar-hidden z-10 !h-[calc(100dvh_-_16px)] rounded-t-3xl !p-0 !px-5"
            @update:model-value="onDateChange"
          >
            <template #header>
              <div>
                <UiTitle severity="h5">календарь</UiTitle>
              </div>
            </template>
            <template #confirm-text>Показать сеансы</template>
          </UiDatePicker>
        </UiDialog>
      </div>
    </UiContainer>
    <UiContainer class="flex gap-6">
      <section class="relative w-full">
        <CategoryResultsMasonry :value="category" :loading="!categoryInitialized" />
        <UiEternalLoading
          v-if="!allEventsLoaded"
          :handler="loadMoreEvents"
          :all-loaded="allEventsLoaded"
        />
        <UiScrollUpButton class="!sticky bottom-10 z-10 ml-auto mr-0" />
      </section>
      <section
        :class="[
          'calendar-block',
          'sticky right-0 top-2 hidden h-fit w-full max-w-[276px] overflow-hidden rounded-xl border border-sixfold p-3 shadow-sixfold',
          'after:absolute after:bottom-0 after:left-0 after:h-5 after:w-full after:content-[\'\']',
          'md:block'
        ]"
      >
        <UiDatePicker
          :model-value="datepickerValue"
          range
          show-reset
          header-classes="mb-3"
          class="custom-scrollbar max-h-[454px] overflow-x-hidden overflow-y-scroll"
          @update:model-value="onDateChange"
        >
          <template #header>
            <UiTitle severity="h5" class="mr-auto">календарь</UiTitle>
          </template>
        </UiDatePicker>
      </section>
    </UiContainer>
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { CategoryResultsMasonry } from '@/3_widgets/Category'
import { GoBack } from '@/4_features/Navigation'
import { type CategoryFilters, useCategory } from '@/5_entities/Category'
import { usePageFilters } from '@/6_shared/composables'
import { useBreakpoint } from '@/6_shared/lib'
import {
  UiButton,
  UiContainer,
  UiDatePicker,
  UiDialog,
  UiEternalLoading,
  UiIcon,
  UiScrollUpButton,
  UiTitle
} from '@/6_shared/ui'

const route = useRoute()
const {
  md: [_, isMdAndDown]
} = useBreakpoint()
const { filters, updateDateFilters, datepickerValue } = usePageFilters<CategoryFilters>(24)

const onDateChange = (value?: Date | Date[]) => {
  updateDateFilters(value)
  loadCategory()
  mobileCalendarIsOpen.value = false
}
const mobileCalendarIsOpen = ref(false)

const { category, categoryInitialized, allEventsLoaded } = storeToRefs(useCategory())
const { getCategory, loadNextEventsPage } = useCategory()

const loadCategory = () => getCategory(Number(route.params.id), filters.value)
const loadMoreEvents = () => loadNextEventsPage(filters.value)

onBeforeMount(() => {
  loadCategory()
})
</script>
